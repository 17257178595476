import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  AfterContentInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule],
  selector: 'app-confirm-dialog',
  template: `
    <div class="modal-container">
      <div class="modal-header">
        <h3>{{ headerText | translate }}</h3>
        <button
          type="button"
          class="btn-close btn-close-white"
          aria-label="Close"
          data-dismiss="modal"
          (click)="modal.dismiss()"
        ></button>
      </div>
      <div class="modal-body overflow-auto" style="max-height: 60vh">
        <div #bodyWrapper>
          <ng-content></ng-content>
        </div>

        <div *ngIf="!hasBody" class="modal-body">
          <div *ngIf="!useDangerMessage">
            <span>{{ bodyText | translate }}</span>
            <ul *ngIf="extraBodyItems">
              <li *ngFor="let item of extraBodyItems">{{ item }}</li>
            </ul>
          </div>
          <div *ngIf="useDangerMessage">
            <p>
              <strong>{{ bodyText | translate }}</strong>
            </p>
            <ul *ngIf="extraBodyItems">
              <li *ngFor="let item of extraBodyItems">{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="modal.dismiss()"
        >
          {{ dismissButtonText | translate }}
        </button>
        <button
          [disabled]="!closeButtonEnabled"
          type="button"
          [class.btn-outline-danger]="useDangerButton"
          [class.btn-outline-success]="!useDangerButton"
          class="btn"
          (click)="accept()"
        >
          {{ closeButtonText | translate }}
        </button>
      </div>
    </div>
  `,
})
export class ConfirmDialogComponent implements AfterContentInit {
  @ViewChild('bodyWrapper', {
    static: true,
  })
  bodyWrapper?: ElementRef;

  /** compose a list of items in the body */
  @Input() extraBodyItems: string[] | undefined = undefined;
  /** enable/disable confirmation button */
  @Input() closeButtonEnabled = true;
  /** mark confirmation button as dangerous */
  @Input() useDangerButton = false;
  /** use danger message */
  @Input() useDangerMessage = false;

  /** label of NO button */
  @Input() dismissButtonText = 'NO';
  /** label of the confirmation button (YES) */
  @Input() closeButtonText = 'YES';
  @Input() headerText = 'CONFIRM_DIALOG_HEADER';
  @Input() bodyText = 'CONFIRM_DIALOG_MESSAGE';
  hasBody = false;

  constructor(public modal: NgbActiveModal) {}

  ngAfterContentInit() {
    this.hasBody =
      !!this.bodyWrapper &&
      this.bodyWrapper.nativeElement.childNodes.length > 0;
  }
  accept() {
    this.modal.close(true);
  }
}
